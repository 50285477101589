body{
    background:#FFFFFF;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size:18px;
    font-weight:300;
    color:#000000;
    min-width: 300px;
}

#Container .mix{
    display: none;
}

.clear{
    clear:both;
}

img {
    margin:0;
}

.bg_info{
    display: none;
}

.img-responsive_full{
    display: block;
    width: 100%;
    height: auto;
}


b{
    font-weight:400;
}

h1{
    font-size:30px;
    font-weight:500;
    text-transform: uppercase;
}


a {
    color:#0CB8F2;
    border:none;
    text-decoration:underline;
}

a:hover,
.active a{
    color:#0CB8F2;
    text-decoration:underline;
}
.marginleft{
    margin-left: 35px;
}
.row_margin{
    margin-right:0;
    margin-left:0;
}

.pad{
    padding-left:0;
    padding-right:0;
}

.center-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.header{
    padding:40px 0 25px 0;
}

.lingue{
    padding-top:10px;
    float:right;
}

.lingue ul{
    padding-left:0;
}

.lingue li{
    list-style-type:none;
    text-align:center;
    font-size:15px;
    margin-bottom:20px;
}

.lingue li a{
    text-decoration:none;
    padding:3px;
    color:#000000;
}

.lingue li a:hover,
.lingue li a.active{
    text-decoration:none;
    color:#000000;
    border-bottom:6px solid #000;
}

.menu_filtro{
    background-color: #ffffff;
    border-color: #ffffff;
}

.menu_pulsanti{
    padding-left:0;
    text-align: center;
}

.menu_pulsanti li{
    list-style-type:none;
    display: inline-block;
    padding:0 22px;
}

.navbar-default .navbar-nav>li>a{
    color:#000;
    text-decoration:none;
}

.navbar-default .navbar-nav>li>a{
    padding:5px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a.active{
    color:#000;
    text-decoration:none;
    border-bottom:6px solid #000;
}


.sel-lingua a{
    color:#000;
    text-decoration:none;
}

.sel-lingua a{
    padding:5px;
}

.sel-lingua a:hover,
.sel-lingua a.active{
    color:#000;
    text-decoration:none;
    border-bottom:6px solid #000;
}

.riga_colori{
    padding:10px 0;
}

.tiolo_classifica{
    text-align:center;
    text-transform: uppercase;
    min-height: 110px;
    font-size: 16px;
}

.tiolo_classifica a{
    text-decoration: none;
    color:#000;
}



.elenco_classifiche{
    padding:10px;
}

.img_center{
    margin:0 auto;
}

.riga_classifica{
    margin-bottom:25px;
}

.spazio_banner{
    margin:50px 0;
}

.testo_home{
    text-align:center;
    margin:20px 0 50px 0;
}

.testo_footer{
    color:#666666;
    font-size:16px;
    text-align:center;
    list-style: none;
    padding-left: 0px;
    margin: 0px;
    padding: 0pc;

}
.testo_footer li{
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid black; 
}
.testo_footer li.first{
    padding-left: 0px;
    margin-left: 0px;
    border-left: 0px solid black; 
}

.spazio_footer{
    margin:50px 0 20px 0;
}

.testo_footer a{
    color:#666666;
}

.navbar-nav>li{
    float:none;
}

.navbar-nav{
    float:none;
}

.pagination>li>a, .pagination>li>span{
    color:#000;
}

.pagination>li>a.active{
    background-color: orange;
}

.titolo_centro{
    text-align:center;
    margin:10px 0;
}

.primi{
    font-weight:700;
    float:left;
    padding: 33px 4px;
    margin: 0;
        width: 22px;
}

.primi_mob{
    font-weight:700;
    padding: 10px 4px;
    margin: 0;
    text-align:center;
}

.dopo_mob{
    font-weight:300;
    padding: 10px 4px;
    margin: 0;
    text-align:center;
}

.dopo{
    font-weight:300;
    float:left;
    padding: 33px 4px;
    margin: 0;
}

.meno_info,
.piu_info{
    color:#000;
    padding: 37px 10px;
    text-decoration:none;
    margin-left:5px;
}

.spazio_classifica{
    margin:40px 0 50px 0;
    font-size: 15px;
}

.img_classifica{
    float:left;
    margin-left:20px;
    width: 90px;
    height: 90px;
}

.spazio_img_clas{
    padding: 0 10px;
}

.spazio_img_clas_doppio{
    padding:0px 10px 0px 0px;
}

.dida{
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.dida li{
    float: left;
    margin: 5px;
    line-height: 18px;
} 

.icogreen{
    font-size:20px; 
    color:#36e71e;
}

.icoazzurra{
    font-size:20px; 
    color:#0CB9F5;
}

.icogiallo{
    font-size:20px; 
    color:#FAAB4C;
}

.icofucsia{
    font-size:20px; 
    color:#D6256D;
}

.green{
    border-right:3px solid #36e71e;
}

.azzurra{
    border-right:3px solid #0CB9F5;
}

.giallo{
    border-right:3px solid #FAAB4C;
}

.fucsia{
    border-right:3px solid #D6256D;
}

.riga_grigio{
    border-bottom:1px solid #CCCCCC;
    background-color:#FAFAFA;
    padding:11px 0;
}

.riga_grigio_mob{
    border-bottom:1px solid #CCCCCC;
    background-color:#FAFAFA;
    padding:6px 0;
}

.riga_bianco_mob{
    border-bottom:1px solid #CCCCCC;
    padding:6px 0;
}


.riga_bianco{
    padding:11px 0;
    border-bottom:1px solid #CCCCCC;
}

.prima_voce{
    font-weight:700;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 32px 0 0 0;
}

.seconda_voce{
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 32px 0 0 0px;
}

.prima_voce_mob{
    font-weight:700;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 5px 0;
    font-size:14px;
}

.seconda_voce_mob{
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 5px 0;
    font-size:12px;
}

.spazio_clas_mob{
    padding:0;
}

.spazio_pulsante{
    padding:18px;
}



.icone_classifica{
    padding: 33px 10px;
    width: 4%;
}

.ico_classifica_mob{
    padding: 17px 8px;
    margin: 0 auto;
}

.spazio_prima_voce{
    width: 37%;
}

.spazio_tab{
    margin:50px 0;
}

.img_sotto{
    position:relative;
}

.img_sopra{
    position:absolute;
    top:0;
    z-index:20;
    
}

.spazio_due{
    padding: 0;
}

.testo_prima_riga{
    font-size:15px;
    font-weight:400;
}

.spazio_pulsante a{
    background:url(../images/download_itunes.png) no-repeat center center;
    display:block;
    height:52px;
        background-size: contain;
}

.spazio_pulsante a:hover,
.spazio_pulsante a.active{
    background:url(../images/download_itunes_hover.png) no-repeat center center;
    display:block;
    height:52px;
     background-size: contain;
}

.spazio_pulsante_mob{
    background:url(../images/itune_mob.png) no-repeat center center;
    display:block;
    height:30px;
    margin: 23px 0;
    width:30px;
}

.spazio_pulsante_mob a{
    height:50px;
    width:50px;
    display:block;
}


.spazio_img_clas_doppio .meno_info{
    display:none;
}

.spazio_img_clas_doppio .piu_info{
    display:block;
    padding-left:149px;
}


.spazio_img_clas .piu_info{
    display:block;
    padding-left:138px;
}



.spazio_img_clas_mob .meno_info_mob{
    display:none;
}

.spazio_img_clas_mob .piu_info_mob{
    display:block;
    padding-left:0;
    float:left;
}

.spazio_img_clas_mob .meno_info_mob,
.piu_info_mob{
    color:#000;
    padding: 22px 10px;
    text-decoration:none;
    margin-left:5px;
}



.info_chart{
    text-decoration:none;
}

.info_chart:hover,
.info_chart.active{
    text-decoration:none;
}

.pulsante_more{
    margin:0 auto;
}

.titolo_tag{
    font-weight:700;
}

.pulsanti_tag{
    text-decoration:none;
    padding: 5px 8px;
    border-radius:0;
    margin: 2px 0px;
}

.pulsanti_tag:hover{
    background-color:#FAFAFA; 
}

.spazio_tag{
    margin:20px 0;
}
.tags{
    margin:0px;
    padding: 0px;
}
.tags li{
    float: left;
    list-style: none;
    margin-right: 4px;
}

.tags .selected{
    background-color: orange;
}

.pulsante_indietro{
    border-bottom:6px solid #CCCCCC;
    border-top:0;
    border-left:0;
    border-right:0;
    border-radius:0;
    text-decoration:none;
    font-size: 15px;
    font-weight: 300;
    padding: 9px 38px;
}

.pulsante_indietro:hover,
.pulsante_indietro.active{
    background-color:#fff;
    text-decoration:none;
    border-bottom:6px solid #BEBEBE;
}

.icona_sx{
    font-size:13px;
}

.testo_info{
    color:#fff;
    text-align:left;
}

.bg_info{
    background-color:#000;
    padding:20px 50px;
    color: white;
}

.spazio_contenuto_mob{
    padding: 11px 10px 11px 0px;
}

.spazio_testo_profilo{
    padding:30px 0;
}

.paginazione_centro{
    text-align:center;
}


/* SOLO TABLET */
@media (max-width: 1280px){
    

    
}

/* SOLO TABLET */
@media (max-width: 1200px){
  
.menu_pulsanti li{
    list-style-type:none;
    /*display: inline;*/
    padding:0 10px;
}

.spazio_img_clas .piu_info {
    display: block;
    padding-left: 115px;
}
.img_classifica {
    float: left;
    margin-left: 5px;
    width: 90px;
    height: 90px;
}

.container, .container-fluid {
    padding-left: 0px;
   padding-right: 0px; 
}

    
}


/* SOLO TABLET */
@media (max-width: 1021px){

    .sel-lingua{
        margin-bottom: 20px;
    }
    .testo_footer li{
        float: left;
        padding-left: 5px;
        margin-left: 5px;
        border-left: 0px solid black; 
        padding: 10px;
    }
    .testo_footer li.lingua{
        clear: both;
    }

    .testo_footer li.link{
        clear: both;
    }
    .testo_footer li:last-child{
        clear: both;
    }
    .bg_info{
    font-size:15px;
}
}

/* SOLO MOBILE */
@media (max-width: 767px) {


.menu_pulsanti li{
    list-style-type:none;
    display: inline;
    padding:0 10px;
}
.menu_filtro{
    background-color: #ffffff;
    border-color: #E2E2E2;
}

.menu_pulsanti{
    padding-left:15px;
    text-align: left;
}

.lingue_mobile{
    padding:0!important;
    margin-bottom: 16px;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a.active{
    color:#000;
    text-decoration:none;
    border-bottom:none;
    background-color:#F2F2F2;
}

.titolo_centro{
    font-size:20px;
}

.icone_classifica{
    padding: 39px 10px;
    width: auto;
}

.spazio_prima_voce{
    width: auto;
}

.bg_info{
    font-size:15px;
}

.bg_info{
    padding: 15px 20px;
}

.clearOnMobile{
    clear: both;
} 

.col-xs-1 {
    width: 12.333333% !important;
}

.testo_footer{
    margin: 0px;
    padding: 0px;
    width: 100%;
    text-align: center;
}

li.first{
    width: 100%;
    text-align: center;
}

}

